<template>
  <LayoutNew>
    <h1 class="text-center">Daily Video Updates</h1>
    <MDBCard id="UploadFile" class="mb-2">
      <MDBCardHeader>
        <MDBContainer fluid>
          <!-- buttons -->
          <MDBRow class="py-2 px-0">
            <MDBCol col="12">
              <h4>Notifications</h4>
            </MDBCol>
            <MDBCol col="7">
              <MDBBtn type="button" @click.prevent="getNotificationList" color="primary" size="sm"
                class="text-capitalize">
                <em class="fas fa-sync mr-2" />
                Refresh List
              </MDBBtn>
            </MDBCol>
            <MDBCol col="5" class="text-end pe-md-4">
              <MDBBtn type="button" color="primary" size="sm" class="text-capitalize" @click.prevent="openFilterModal">
                <em class="fas fa-filter mr-2" />
                Filter
              </MDBBtn>
              <MDBBtn type="button" @click.prevent="resetFilter" outline="primary" size="sm" class="text-capitalize">
                <em class="fas fa-undo mr-2" />
                Reset Filter
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <!-- filter tags -->
          <MDBRow class="overflow-auto">
            <MDBCol>
              <div class="d-inline-flex py-1">
                <MDBChip outline="primary" alt="filterDate" close v-if="filterDate" @close-chip="deleteDate">
                  <b class="text-nowrap">Filter Date: {{ filterDate }}</b>
                </MDBChip>
                <MDBChip outline="primary" alt="tempCreatorSearch" close v-if="creatorSearch"
                  @close-chip="deleteCreatorSearch">
                  <b class="text-nowrap">Creator Name: {{ creatorSearch }}</b>
                </MDBChip>
                <MDBChip outline="primary" alt="filterString" close v-if="filter != '0'" @close-chip="deleteFilter">
                  <b class="text-nowrap">Status: {{ filterString }} </b>
                </MDBChip>
                <MDBChip outline="primary" alt="socialMediaFilterString" close v-if="socialMediaFilter != '0'"
                  @close-chip="deleteMedia">
                  <b class="text-nowrap">Social Media: {{ socialMediaFilterString }} </b>
                </MDBChip>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCardHeader>
      <!-- table -->
      <MDBCardBody>
        <div class="notification-list overflow-auto">
          <div class="loading" v-if="loadingList">
            <MDBSpinner />
          </div>
          <!-- <NotificationTable :notification="item" /> -->
          <MDBTable striped hover class="align-middle" v-else>
            <thead>
              <tr class="table-dark">
                <th>Status</th>
                <th>Title</th>
                <th>Social Media</th>
                <th>Date</th>
                <th>Creator Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="notificationList.data.length === 0">
                <td colspan="6" class="text-center">No Notification Found.</td>
              </tr>
              <tr v-else v-for="(item, i) in notificationList.data" :key="i" v-on:dblclick="
                rowClick(item.id, item.statusTypeId, item.videoLink)
                " :class="item.statusTypeId == 1 ? 'table-success' : 'table-primary'
                  ">
                <DailyVideoUpdateTableRowNew :notification="item" />
              </tr>
            </tbody>
          </MDBTable>
        </div>
      </MDBCardBody>
      <MDBCardFooter class="pb-0">
        <Paginate :pageType="'notificationList'" :statusId="filter" :socialMediaType="socialMediaFilter"
          :creator="creatorSearch" :dateAdded="filterDate" @paginationData="notificationList = $event"></Paginate>
      </MDBCardFooter>
    </MDBCard>
    <!-- modal -->
    <MDBModal id="DailyVideoUpdateFilter" tabindex="-1" labelledby="DailyVideoUpdateFilterLabel"
      v-model="dailyVideoUpdateFilterModal" size="lg">
      <MDBModalHeader class="px-4 py-3">
        <MDBModalTitle id="DailyVideoUpdateFilterLabel">
          Filter Notifications
        </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody id="DailyVideoUpdateModal">
        <MDBContainer fluid class="pb-2">
          <MDBRow class="d-md-flex d-block">
            <MDBCol>
              <label for="tempFilterDate">Filter Date:</label>
              <input type="date" name="tempFilterDate" id="tempFilterDate" v-model="tempFilterDate"
                class="form-control" />
            </MDBCol>
            <MDBCol>
              <label for="tempCreatorSearch" class="pt-md-0 pt-3">Creator Name:</label>
              <MDBInput type="text" v-model="tempCreatorSearch" />
            </MDBCol>
            <MDBCol>
              <label for="statusFilterDropdown" class="pt-md-0 pt-3">Status:</label>
              <MDBSelect v-model:options="statusFilterOptionsRender" v-model:selected="tempFilter"
                name="statusFilterDropdown" id="statusFilterDropdown" :key="filter" />
            </MDBCol>
            <MDBCol>
              <label for="socialMediaFilterDropdown" class="pt-md-0 pt-3">Social Media:</label>
              <MDBSelect v-model:options="socialMediaTypeFilterOptionsRender" v-model:selected="tempSocialMediaFilter"
                name="socialMediaFilterDropdown" id="socialMediaFilterDropdown" />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
      <MDBModalFooter class="px-4 py-3 gap-2">
        <MDBBtn size="sm" @click="closeFilterModal" class="text-capitalize">Close</MDBBtn>
        <MDBBtn size="sm" @click.prevent="saveFilters" color="primary" class="text-capitalize">
          Save
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  </LayoutNew>
</template>

<script setup>
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBSelect,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBChip,
  MDBTable,
  MDBContainer,
} from "mdb-vue-ui-kit";
import { onMounted, ref } from "vue";
import axios from "axios";
import Paginate from "@/components/Pagination/NotifListPagination.vue";
import { useToastStore } from "../../store/toastStore";
import DailyVideoUpdateTableRowNew from "./DailyVideoUpdateTableRowNew.vue";
import LayoutNew from "./LayoutNew.vue";
import { useTitle } from "@vueuse/core";
import { parseDate, parseDateFromInput } from "../../helpers/parseDate";

useTitle("Daily Video Updates | CreatorShield");

const dailyVideoUpdateFilterModal = ref(false);
const toastStore = useToastStore();

const notificationList = ref({
  data: [],
  pagination: {
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 15,
  },
});

const rowClick = async (notificationId, statusId, link) => {
  window.open(link, "_blank", "noreferrer");
  if (statusId == 1) {
    updateNotificationStatus(notificationId);
  }
};

const updateNotificationStatus = async (notificationId) => {
  const formData = {
    status: 2,
  };
  var url = "api/notifications/" + notificationId + "/status";
  axios
    .patch(url, formData, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      if (response.status == 200) {
        getNotificationList();
      }

      if (response.status == 422) {
        toastStore.toastObject = {
          ...toastStore.toastObject,
          ...toastStore.toastStatus.fail,
        };
      }
    })
    .catch(function () {
      toastStore.toastObject = {
        ...toastStore.toastObject,
        ...toastStore.toastStatus.fail,
      };
    });
};

const socialMediaTypeFilterOptions = ref([
  { text: "Facebook", value: 1 },
  { text: "Instagram", value: 2 },
  { text: "Tiktok", value: 3 },
  { text: "Youtube", value: 4 },
  { text: "Douyin", value: 5 },
  { text: "Kuaishou", value: 6 },
]);

const socialMediaTypeFilterOptionsRender = ref([]);

const statusFilterOptions = ref([
  { text: "New", value: 1 },
  { text: "Viewed", value: 2 },
  { text: "Qualified", value: 4 },
  { text: "Not Qualified", value: 5 },
  { text: "Ingested", value: 3 },
]);

const statusFilterOptionsRender = ref([]);
const loadingList = ref(false);

const filterDate = ref("");
const tempFilterDate = ref("");
const deleteDate = () => {
  filterDate.value = "";
  tempFilterDate.value = "";
  getNotificationList();
};

const filter = ref(0);
const tempFilter = ref(0);
const filterString = ref("");
const deleteFilter = () => {
  filter.value = 0;
  tempFilter.value = 0;
  filterString.value = "";
  getNotificationList();
};

const getNotificationListCommon = async (url) => {
  loadingList.value = true;
  await axios
    .get(url)
    .then(function (response) {
      notificationList.value.data = response.data.data;
      notificationList.value.pagination = response.data.pagination;
      loadingList.value = false;
    })
    .catch(function () {
      toastStore.toastObject = {
        ...toastStore.toastObject,
        ...toastStore.toastStatus.fail,
      };

      loadingList.value = false;
    });
};

const socialMediaFilter = ref(0);
const tempSocialMediaFilter = ref(0);
const socialMediaFilterString = ref("");
const deleteMedia = () => {
  socialMediaFilter.value = 0;
  tempSocialMediaFilter.value = 0;
  socialMediaFilterString.value = "";
  socialMediaTypeFilterOptionsRender.value = [];
  getNotificationList();
};

const creatorSearch = ref("");
const tempCreatorSearch = ref("");
const deleteCreatorSearch = () => {
  creatorSearch.value = "";
  tempCreatorSearch.value = "";
  getNotificationList();
};

const openFilterModal = () => {
  tempCreatorSearch.value = `${creatorSearch.value}`;
  tempFilterDate.value = `${filterDate.value ? parseDateFromInput(filterDate.value) : ""
    }`;

  tempFilter.value = Number(filter.value);
  statusFilterOptionsRender.value = [
    {
      text: "Select Status",
      value: 0,
      selected: filter.value === 0,
      disabled: true,
    },
    ...statusFilterOptions.value.map((option) =>
      option.value === filter.value ? { ...option, selected: true } : option
    ),
  ];

  tempSocialMediaFilter.value = Number(socialMediaFilter.value);
  socialMediaTypeFilterOptionsRender.value = [
    {
      text: "Select Media",
      value: 0,
      selected: socialMediaFilter.value === 0,
      disabled: true,
    },
    ...socialMediaTypeFilterOptions.value.map((option) =>
      option.value === socialMediaFilter.value
        ? { ...option, selected: true }
        : option
    ),
  ];

  dailyVideoUpdateFilterModal.value = true;
};

const saveFilters = () => {
  setValues();
  notificationList.value = {
    data: { ...notificationList.value.data },
    pagination: { ...notificationList.value.pagination, currentPage: 1 },
  };
  getNotificationList().then(() => {
    closeFilterModal();
  });
};

const setValues = () => {
  filter.value = Number(tempFilter.value);
  filterString.value =
    tempFilter.value != 0
      ? statusFilterOptions.value.find(
        (option) => option.value === tempFilter.value
      )?.text
      : 0;

  socialMediaFilter.value = Number(tempSocialMediaFilter.value);
  socialMediaFilterString.value =
    tempSocialMediaFilter.value != 0
      ? socialMediaTypeFilterOptions.value.find(
        (option) => option.value === tempSocialMediaFilter.value
      )?.text
      : "";

  creatorSearch.value = `${tempCreatorSearch.value}`;
  filterDate.value = `${tempFilterDate.value ? parseDate(tempFilterDate.value) : ""
    }`;
};

const closeFilterModal = () => {
  dailyVideoUpdateFilterModal.value = false;
  resetFilters();
};

const resetFilters = () => {
  tempFilter.value = "";
  tempSocialMediaFilter.value = "";
  tempCreatorSearch.value = "";
  tempFilterDate.value = "";
};

const getNotificationList = async () => {
  var url = "api/notifications/list?";
  var currentPage = notificationList.value.pagination.currentPage || 1;
  if (currentPage) {
    var currentPageQuery = "pageNumber=" + currentPage;
    url += formatUrl(url, currentPageQuery);
  }
  if (filter.value && filter.value !== 0) {
    var statusQuery = "statusId=" + filter.value;
    url += formatUrl(url, statusQuery);
  }

  if (socialMediaFilter.value && socialMediaFilter.value !== 0) {
    var socialMediaTypeQuery = "socialMediaType=" + socialMediaFilter.value;
    url += formatUrl(url, socialMediaTypeQuery);
  }

  if (creatorSearch.value !== "") {
    var creatorQuery = "creator=" + creatorSearch.value;
    url += formatUrl(url, creatorQuery);
  }

  if (filterDate.value !== "") {
    var dateQuery = "dateAdded=" + filterDate.value;

    url += formatUrl(url, dateQuery);
  }

  getNotificationListCommon(url);
};

const formatUrl = (currentUrl, urlToAdd) => {
  if (currentUrl.substr(currentUrl.length - 1) === "?") {
    return urlToAdd;
  } else {
    return "&" + urlToAdd;
  }
};

const resetFilter = () => {
  filter.value = 0;
  filterString.value = "";
  socialMediaFilter.value = 0;
  socialMediaFilterString.value = "";
  creatorSearch.value = "";
  filterDate.value = "";
  getNotificationList();
};

onMounted(() => {
  getNotificationList();
});
</script>

<style scoped>
.card,
.card-body {
  overflow-y: auto;
}

.notification-list,
.notification-filters {
  padding-block: 10px;
}

.notification-list .loading {
  text-align: center;
  color: var(--primary);
}

.btn-primary {
  background-color: var(--primary);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.chip {
  line-height: 16px;
}

.page-item.active {
  background-color: var(--primary);
}

.table-dark {
  --mdb-table-bg: var(--accent);
  border-color: var(--accent);
}

tbody tr td {
  cursor: pointer;
}
</style>
