/**
 * Simplify Date Parsing across the app
 * See https://day.js.org/docs/en/display/format for more info about formatting.
 */

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export const parseDate = (date) => {
  return dayjs(date).format("MM/DD/YYYY");
};
export const parseDateDash = (date) => {
  return dayjs(date, "DD/MM/YYYY").format("MM-DD-YYYY");
};
export const parseDateDashLong = (date) => {
  return dayjs(date, "DD/MM/YYYY").format("MMMM DD, YYYY");
};
export const parseDateDayOfWeek = (date) => {
  return dayjs(date).format("dddd");
};
export const parseDateLong = (date) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format("MMMM DD, YYYY");
};
export const parseDateLongWithTime = (date) => {
  return dayjs(date).format("MMMM DD, YYYY hh:mm A");
};
export const parseTime = (time) => {
  return dayjs(time, "HH:mm:ss").format("hh:mm A");
};
export const parseDateTime = (date, time) => {
  return parseDate(date) + " " + parseTime(time);
};
export const isDateValid = (date) => {
  return dayjs(date).isValid();
};
export const isDateInputValid = (date) => {
  return dayjs(date, "YYYY-MM-DD", true).isValid();
};
export const isDatePastDated = (date) => {
  return dayjs(date).isBefore(dayjs(), "day");
};
export const parseTimeFromDate = (date) => {
  return dayjs(date).format("hh:mm A");
};
export const getMonthFullFormat = (month) => {
  return dayjs(month.toString(), "M").format("MMMM");
};
export const getPreviousMonth = () => {
  return dayjs().subtract(1, "month").format("M");
};
export const getCurrentMonth = () => {
  return dayjs().format("M");
};
export const getCurrentYear = () => {
  return dayjs().format("YYYY");
};
export const parseDateForBackend = (date) => {
  return dayjs(date, "DD/MM/YYYY").format("YYYY-MM-DD");
};
export const parseDateFromInput = (date) => {
  return dayjs(date, "MM/DD/YYYY").format("YYYY-MM-DD");
};

export const parseSecondsToTime = (time) => {
  const h = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");

  return `${h}:${m}:${s}`;
};
export const showCurrentDateTimeWithSeconds = () => {
  const time = dayjs().format("hh:mm:ss A");
  const date = dayjs().format("dddd, MMMM D, YYYY");
  return { time, date };
};
